import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {

      log:false
    
  },
  getters: {
  },
  mutations: {
    insider (state) {
      state.log=!(state.log);
    }
  },
  actions: {
  },
  modules: {
  }
})
