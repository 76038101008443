// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDo2m6GbZ_DW6ROcwKxIBZSSYR6DJKCQ80",
  authDomain: "weblogged-40bab.firebaseapp.com",
  projectId: "weblogged-40bab",
  storageBucket: "weblogged-40bab.appspot.com",
  messagingSenderId: "84499935904",
  appId: "1:84499935904:web:f96a1df945277a200e8fc5"
};

// Initialize Firebase


const fireapp = initializeApp(firebaseConfig);
const db = getFirestore(fireapp);
export default db; firebaseConfig;