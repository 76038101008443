<template>
  <div>

    <p>{{ msg }}</p>
    {{ this.colorer }}

    <p>{{ llista.length }}</p>

    <div class="floatingData" v-if="tester">
       top + height {{ top + height }}

    offset {{ offset }}
    </div>
 


  

    <hr />
LIST PAINTINGS

    <button @click="byName()" class="btnG">by NAME</button>
    <button @click="byDate()" class="btnG">by DATE</button>
    <button @click="byStyle()"  class="btnG">by STYLE</button>
    <button
  @click="byColor()" class="dropbtn btnG" >COLOR</button>


    <br />
    <hr />
    <br />

    <div class="hello">
      <div class="carder fadeshow" v-for="(item, index) in SHOWERLIST" :key="index">
        <img class="fadeshow piktor imgo" :src="item.src" />
       
        <div class="infotab">
          {{ item.name }}
          <br />
          {{ item.date }}
        </div>
      </div>
    </div>
    <button @click="getTen()">LOAD NEXT</button>
  </div>
</template>

<script>

import db from "../utilities/firebaseConfig";
import { collection, getDocs } from "firebase/firestore";
export default {
  name: "ExpoPaint",
  data() {
    return {
      tester:false,
   top:null,
   height:null,
   offset:null,
   colorer:null,
      db,
      llista: [],
      SHOWERLIST: [],
      Count: 0,

    };
  },
  props: {
    msg: String,
  },
  mounted() {
    this.getit();

  },
  watch:{
    
  },
  
  created() {
this.scrollMe();
  },
  
  unmounted(){

  },
  methods: {
    getColor(){
    if( document.getElementById('blue').checked){
        alert("blue, ok!");
        this.colorer="BlueGreen";
  
    }
    else if(  document.getElementById('red').checked){
        alert("red, ok!");
        this.colorer="RedYellow";
    }
    else if( document.getElementById('grey').checked){
        alert("grey, ok!");
        this.colorer="Grey";
    }
    else if (!document.getElementById('brown').checked ){
      this.colorer="Brown";
    }
    else{
        alert("choose a color");
        this.colorer="none"
       
    }
   

  },

    zoomer(event){
let offsetX = event.offsetX;
let offsetY = event.offsetY;
  let zoomer = event.currentTarget;
  event.offsetX ? offsetX = event.offsetX : offsetX = event.touches[0].pageX
  event.offsetY ? offsetY = event.offsetY : offsetX = event.touches[0].pageX
  const x = offsetX/zoomer.offsetWidth*100
  const y = offsetY/zoomer.offsetHeight*100
  zoomer.style.backgroundPosition = x + '% ' + y + '%';
},
    scrollMe(){
 

                window.onscroll = () => {
       this.top=parseInt(document.documentElement.scrollTop);
      this.height=parseInt(window.innerHeight);
      this.offset=parseInt(document.documentElement.offsetHeight);

  if (this.height  + this.top >= this.offset - 50 ) {
    this.getTen();
  }
};
            },

    async getit() {
      try {
        const snapshot = collection(db, "catalogue");
        const snap = await getDocs(snapshot);
        const listselector = snap.docs.map((doc) => doc.data());
        this.Count = this.llista.length;
        this.llista = listselector;

        for (var i = this.llista.length - 1; i > 0; i--) {
          var j = Math.floor(Math.random() * (i + 1));
          var temp = this.llista[i];
          this.llista[i] = this.llista[j];
          this.llista[j] = temp;
        }
        this.getTen();
      } catch (e) {
        console.log(e);
      }
    },    
  
    async getot() {
      try {
        const snapshot = collection(db, "catalogue");
        const snap = await getDocs(snapshot);
        const listselector = snap.docs.map((doc) => doc.data());
        this.Count = this.llista.length;
        this.llista = listselector;


      } catch (e) {
        console.log(e);
      }
    },
    getTen() {

      const ShowedItems = this.SHOWERLIST.length;
      for (let i = ShowedItems; i < ShowedItems + 3; i++) {
        this.SHOWERLIST.push(this.llista[i]);
      }
    },
    byName(){

      
this.llista.sort((a,b)=> a.name > b.name  ? 1 : -1);
this.SHOWERLIST=[];
this.getTen();

    //________________________BOBBOROTTI!!!__________________________//
// SORT by ALPHABET with TERNARY//

    },
    byDate(){
      this.llista.sort((a,b)=>b.date-a.date)
     this.SHOWERLIST=[];
     this.getTen();

  // SORT by NUMERICAL with ARROW FUNCTION//



    },
    byStyle(){

      this.llista.sort((a,b)=> a.style > b.style  ? 1 : -1)
     this.SHOWERLIST=[];
     this.getTen();
  
    },
    byColor(){
     

      this.llista.sort((a,b)=> a.color > b.color ? 1 : -1)
     this.SHOWERLIST=[];
     this.getTen();
    }
  },
};
</script>

<style lang="css" scoped>
p {
  font-size: 9px;
}

ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
img {
  max-height: 50%;
  max-width: 95%;
}
input {
  border-radius: 5px;
  width: 100px;
  margin: 5px;
}
.btnG {
  padding: 2px;
  background-color: grey;
  font-size: 12px;
  color: rgb(195, 195, 195);
  border: solid 1px slategray;
  border-radius: 5px;
  box-shadow: 1px 3px 10px grey;
  margin-left: 5px;
}
.btnG:hover {
  padding: 4px;
  color: white;
  border: solid 1px rgb(0, 0, 0);
}
.btnG:active {
  background-color: black;
}

.G {
  width: 100px;

  vertical-align: middle;
}
.diver {
  margin-top: 200px;
}

.floatingData{
  position: fixed;

  z-index:10;
}
.imgo {
    transition: -webkit-transform 0.25s ease;
    transition: transform 0.25s ease;
}

.imgo:active {
    -webkit-transform: scale(3);
    transform: scale(3);
}
.sm{width:50px}
/* Style The Dropdown Button */
.dropbtn {
  padding: 2px;
  background-color: grey;
  font-size: 12px;
  color: rgb(195, 195, 195);
  border: solid 1px slategray;
  border-radius: 5px;
  box-shadow: 1px 3px 10px grey;
  margin-left: 5px;
  cursor: pointer;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display:none;
  position: absolute;
  background-color: silver;
  max-width: 75px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}
.dropdown-content-blue {
  background: rgb(2,0,36);
background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 22%, rgba(0,212,255,1) 34%, rgba(0,255,23,1) 51%, rgba(13,67,9,1) 72%);

}
.dropdown-content-red {

  background: rgb(113,17,17);
background: linear-gradient(90deg, rgba(113,17,17,1) 0%, rgba(255,74,0,1) 22%, rgba(255,185,0,1) 34%, rgba(255,254,0,1) 51%, rgba(216,209,25,1) 72%, rgba(216,122,25,1) 98%);

}
.dropdown-content-grey {

  background: rgb(78,78,78);
background: linear-gradient(90deg, rgba(78,78,78,1) 0%, rgba(142,142,142,1) 22%, rgba(177,170,150,1) 34%, rgba(121,121,102,1) 51%, rgba(208,208,208,1) 72%, rgba(255,255,255,1) 98%);

}
.dropdown-content-brown {

  background: rgb(110,74,51);
background: linear-gradient(90deg, rgba(110,74,51,1) 0%, rgba(163,78,78,1) 22%, rgba(127,105,40,1) 34%, rgba(84,84,27,1) 51%, rgba(173,135,13,1) 72%, rgba(184,122,74,1) 98%);

}
/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  text-shadow: 1px 1px 5px white;
  padding: 12px 16px;
  text-decoration: none;
  display: flex;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {background-color: #f1f1f1}
.dropdown-content-blue a:hover {background-color: #5089f1}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: #6d6d6d;
}
.colors{
  color:red;
}
</style>
