<template>
    <div class="home fadeshow">
      <img 
      @click="intro=!intro"
      alt="logo" src="../assets/img/crow_logo.svg" :class="{ loguito2:intro, loguito1:!intro}">
      <h1 class="locklogo">クアドロス</h1>Ivan Cuadros - Freelance Artist
      <p style="color:white">Art + Illustration + Multimedia</p>
      <div
      v-if="intro">
          <ExpoPaint msg="Artworks in Catalogue: "/>
      
      </div>
      <h3 
      class="buttonier"
      @click="intro=!intro"
      v-if="!intro">Artwork</h3>
      <a href="https://www.upwork.com/freelancers/~012894ee17b3a6616a" target="_blank" style="color: #2c3e50;text-decoration: none;">
  
      <h3 
      class="buttonier"
      @click="intro=!intro"
      v-if="!intro">Contract</h3>
  </a>
  <a href="mailto:ivancuadros@gmail.com" style="color: #2c3e50;text-decoration: none;">
      <h3 
      class="buttonier"
      @click="intro=!intro"
      v-if="!intro">Email</h3>
    </a>
    </div>
  </template>
  
  <script>

  import ExpoPaint from '@/components/ExpoPaint.vue'
  
  export default {
    name: 'SplashPage',
    data(){
      return{
        intro:true
      }
    },
    components: {
      ExpoPaint
    }
  }
  </script>
  
  