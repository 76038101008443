<template>
    <div>
    <nav>
<div class="container">
<div class="row box linknav">

<h1>

   
      <router-link to="/">CUADROS</router-link>  | 

      <router-link to="/about">SKILLS</router-link> | 
  
      <router-link to="/link1">CONTRACT</router-link>  | 

      <router-link to="/link2">Projects</router-link> 
  

    

</h1>
</div>
</div>
    

    </nav>

    </div>
</template>

<script>


    export default {
        name:'NavBar'
    }
</script>

<style lang="css" scoped>
ul{
    list-style-type:none;
    text-align: left;
    margin: 0;
}
</style>